@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container{
  max-width: 1290px !important;
}
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    /* transition: all .3s linear; */
}
p,h1,h2,h3,h4,h5,h6{
  margin: 0 !important;
}

html{
  font-family: 'Poppins', sans-serif;
}

body{
  font-family: "Poppins" !important;
}

.ant-modal-content{
  border-radius: 7px !important;
}
.drawer-menu a{
  color: black !important;
}